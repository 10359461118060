import '../style/mission.css'

const Mission = () => {
    return(
        <div className="mission_con">
            <div className="mission_text_con">
                <span className="mission_text">Our mission is</span>
                <span className="mission_title">Put the right person into the right job</span>
                <span className="mission_text">to maximize the result and efficiency of the organization and one's self-development.</span>
            </div>
        </div>
    )
}
export default Mission